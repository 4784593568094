<template>
  <div class="work-school">
    <div class="work-head">
      <div class="head-left">
        <el-image
          class="head-icon"
          :src="require(`../../../assets/image/work.png`)"
          fit="contain"
        ></el-image>
        <div class="head-title">AI求职</div>
      </div>
      <el-image
        class="head-right"
        @click="sendExit"
        :src="require(`../../../assets/image/exit2.png`)"
        fit="contain"
      ></el-image>
    </div>
    <div class="work-body">
      <div class="body-data">
        <el-row class="main">
          <el-col :span="24">
            <el-row>
              <el-col :span="9"> <span class="title">AI简历</span></el-col>
              <el-col :span="15">
                <i v-if="success.resumes" class="el-icon-success icon"></i>
                <span class="skip" @click="gotoAiResume" v-else
                  >去完成</span
                ></el-col
              >
            </el-row>
          </el-col>
          <!-- <el-col :span="24">
            <el-row>
              <el-col :span="9">
                <span class="title">AI生涯规划推荐</span></el-col
              >
              <el-col :span="15">
                <i v-if="success.WJ" class="el-icon-success icon"></i>
                <span class="skip" @click="gotoSkip('99,100')" v-else
                  >去完成</span
                ></el-col
              >
            </el-row></el-col
          > -->
          <el-col :span="24">
            <el-row>
              <el-col :span="9">
                <span class="title">MBTI职业性格测试</span></el-col
              >
              <el-col :span="15">
                <i v-if="success.MBTI" class="el-icon-success icon"></i>
                <span class="skip" @click="gotoSkip('3')" v-else>去完成</span>
                <el-select
                  size="small"
                  v-model="resultMBTI"
                  filterable
                  placeholder="MBTI职业测试"
                  @change="handleSelectChange('MBTI')"
                >
                  <el-option
                    v-for="item in MBTIOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option> </el-select
              ></el-col> </el-row
          ></el-col>
          <el-col :span="24">
            <el-row>
              <el-col :span="9">
                <span class="title">霍兰德职业兴趣测试</span></el-col
              >
              <el-col :span="15">
                <i v-if="success.HLD" class="el-icon-success icon"></i>
                <span class="skip" @click="gotoSkip('5')" v-else>去完成</span>
                <el-select
                  size="small"
                  v-model="resultHLD"
                  filterable
                  placeholder="霍兰德职业测试"
                  @change="handleSelectChange('HLD')"
                >
                  <el-option
                    v-for="item in HLDOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option> </el-select
              ></el-col> </el-row
          ></el-col>
        </el-row>
        <div class="data-bottom">
          <el-button class="produce-button" @click="sendMessage"
            >去求职</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkCompletion, insertQuestionnaire } from "@/request/api";

export default {
  data() {
    return {
      success: {
        HLD: false,
        MBTI: false,
        resumes: false,
      },
      hasCompletedQuestionnaire: false,
      resultHLD: "",
      HLDOptions: [
        "【现实型】",
        "【艺术型】",
        "【社会型】",
        "【企业型】",
        "【研究型】",
        "【常规型】",
      ],
      resultMBTI: "",
      MBTIOptions: [
        "INFP<哲学家型>",
        "INFJ<博爱型>",
        "ENFP<公关型>",
        "ENFJ<教导型>",
        "INTP<学者型>",
        "INTJ<专家型>",
        "ENTP<智多星型>",
        "ENTJ<统帅/CEO型>",
        "ISFJ<照顾者型>",
        "ISTJ<检查员型>",
        "ESFJ<主人型>",
        "ESTJ<管家型>",
        "ISFP<艺术家型>",
        "ISTP<冒险家型>",
        "ESFP<表演者型>",
        "ESTP<挑战者型>",
      ], // mbti 测试数据源
    };
  },
  methods: {
    async handleSelectChange(type) {
      // let result = ''
      // if(){

      // }
      const res = await insertQuestionnaire({
        isNew: true,
        classify: type,
        result: this.resultHLD || this.resultMBTI,
      });
      if (res.data.code === "1000") {
        if (type === "MBTI") {
          this.success = { ...this.success, MBTI: true };
        } else {
          this.success = { ...this.success, HLD: true };
        }
      } else {
        this.message.error(res.data.msg);
      }
    },
    async init() {
      try {
        const res = await checkCompletion();
        if (res.status !== 200 || res.data.code !== "1000") {
          this.$message.error(res.data.msg);
          return;
        }
        const stateArr = Object.values(res.data.data);
        this.success = res.data.data;
        this.hasCompletedQuestionnaire = stateArr.every(
          (item) => item === true
        );
      } catch (error) {
        this.$message.error("请求发生错误: " + error.message);
      } finally {
        this.isLoading = false;
      }
    },

    sendExit() {
      this.$emit("update-value");
    },
    sendMessage() {
      if (
        this.hasCompletedQuestionnaire ||
        (this.success.HLD && this.success.MBTI && this.success.resumes)
      ) {
        this.$router.push("workSchoolRecommend");
      } else {
        this.$message({
          message: "请先完成所有问卷",
          type: "warning",
        });
      }
    },
    gotoSkip(student_questionnaire_classify_id) {
      this.$router.push({
        path: "/questionnaire",
        query: {
          student_questionnaire_classify_id: student_questionnaire_classify_id,
        },
      });
    },
    gotoAiResume() {
      this.$router.push("resumeContest");
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.work-school {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .work-body {
    flex: 1;
    display: flex;
    align-items: center;
    .body-data {
      background-color: #fff;
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: column;
      .main {
        width: 50%;
        height: 80%;
        // display: flex;
        // align-items: center;
        margin: 0 auto;
        margin-top: 100px;
        .title {
          font-size: 18px;
          font-weight: bold;
          color: #3e83ff;
          line-height: 40px;
        }
        .skip {
          border-bottom: 1px solid #f4a933;
          cursor: pointer;
          color: #f4a933;
          margin-right: 20px;
        }
        .icon {
          font-size: 20px;
          color: #f4a933;
          margin-right: 20px;
        }
      }
      .data-bottom {
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .produce-button {
          width: 120px;
          color: #fff;
          font-weight: bold;
          background-image: linear-gradient(to right, #7763ea, #569be8);
          box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
          border: none;
          &:hover {
            box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.2);
          }
          &:active {
            box-shadow: inset 0 3px 4px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }
  .work-head {
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      padding-right: 1%;
      width: 70px;
      cursor: pointer;
    }
  }
}
</style>
